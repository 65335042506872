<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Products Table -->
		<a-row :gutter="24" type="flex">

			<!-- Products Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Products Table Card -->
				<CardCompetitorsTable
					:data="table1Data"
					:columns="table1Columns"
				>
				</CardCompetitorsTable>
				<!-- / Products Table Card -->
			</a-col>
			<!-- / Products Table Column -->

		</a-row>
		<!-- / Products Table -->

	</div>
</template>

<script>

	// "Products" table component.
	import CardCompetitorsTable from '../components/Cards/CardCompetitorsTable' ;

	import request from '../utils/request';
	
	import {list} from '@/api/competitor';

	// "Products" table list of columns and their properties.
	const table1Columns = [
		{
            title:"", 
            dataIndex:'competitorid',
            scopedSlots: { customRender: 'selectorColumn' },
            width: 50,
        },
		{
			title: 'Name',
			dataIndex: 'name'
		},
		{
			title: 'Url',
			dataIndex: 'url',
		},
		{
			title: 'Edit',
			dataIndex: 'competitorid',
			key: 'editid',
			scopedSlots: { customRender: 'editBtn' },
			width: 50,
		},
		{
			title: 'Delete',
			dataIndex: 'competitorid',
			key:'delid',
			scopedSlots: { customRender: 'delBtn' },
			width: 50,
		},
	];

	// "Authors" table list of rows and their properties.
	const table1Data = [];
	
	export default ({
		components: {
			CardCompetitorsTable,
		},
		methods: {
			// function: competitor-02
			
		},
		data() {
			return {
				// Associating "Products" table data with its corresponding property.
				table1Data: table1Data,

				// Associating "Products" table columns with its corresponding property.
				table1Columns: table1Columns,
			}
		},
		mounted() {
			// function: competitor-01
			list().then(data=>{
				this.table1Data = data;
			});
		},
	})

</script>

<style lang="scss">
</style>