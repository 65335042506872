<template>
	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">{{data.length}} Competitors</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-button type="primary" icon="plus" shape="round" @click="addCompetitor">
						Add competitor
				</a-button>
				</a-col>
			</a-row>
		</template>
		 <a-checkbox-group style="display:inline" @change="competitorSelected">
		<a-table :columns="columns" :data-source="data" :pagination="true" v-show="data.length">
			<template slot="selectorColumn" slot-scope="row">
				<a-checkbox :value="row"></a-checkbox>
			</template>
			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" class="btn-edit" :data-id="row.key" @click="editcompetitor(row)" icon="edit">
				</a-button>
			</template>
			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" class="btn-edit" :data-id="row.key" @click="delcompetitor(row)" icon="delete">
				</a-button>
			</template>
		</a-table>
		 </a-checkbox-group>
		 <a-button shape="round" @click="handleSelect" type="primary" icon="minus" style="margin-left:10px">Delete selected</a-button>
		
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>
	import { removecompetitor } from '@/api/competitor';
	import { message } from 'ant-design-vue';
	

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				// Active button for the "Authors" table's card header radio button group.
				authorsHeaderBtns: 'all',
				selectedCompetitors:[],
			}
		},
		methods: {
			addCompetitor(){
				this.$router.push({path:'/competitoredit',query:{competitorid:0}});
			},
			// function: competitor-03
			editcompetitor(id){
				this.$router.push({path:'/competitoredit',query:{competitorid:id}})
			},
			// function: competitor-04
			delcompetitor(id){
				removecompetitor(id).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].competitorid==id)
							this.data.splice(i,1);
				message.success('This competitor has been deleted successfully!');
				})
			},
			competitorSelected(values){
            this.selectedCompetitors = values;
            },
			// function: competitor-05
			handleSelect(){
			this.selectedCompetitors .forEach(item => this.delcompetitor(item))
			}

		}
	})

</script>